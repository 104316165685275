@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'roboto';
  src: url('../src/roboto/Roboto-Regular.ttf') format('ttf');
  src: url('../src/roboto/Roboto-Light.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}
.custom-stroke {
  color: transparent;
  -webkit-text-stroke: 2px #68AF21;
}


@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.imageContainer{
  height: 95%;
  width: 95%;
  object-fit: contain;
  padding: 26px;
}
.grid-row {
  grid-auto-rows: minmax(500px, auto);
}
.locations{
  display: grid;
grid-template-rows: 1fr max-content;
}
input::placeholder{
  font-size: "13px";
}